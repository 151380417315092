@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.react-video-wrap {
	padding-bottom: 36px !important;
	position: relative !important;
}

.react-video-wrap video {
	background-color: #000000;
	display: block;
	height: 100%;
	width: 100%;
}

.react-video-controls {
	background-color: #e7e7e7;
	display: flex;
	height: 36px;
	padding: 0 7px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.react-video-controls button {
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center center;
	border: 0;
	cursor: pointer;
	margin: 10px 7px;
	padding: 0;
	outline: none;
	height: 16px;
	width: 16px;
	text-indent: -9999px;
}

.react-video-controls .play {
	background-image: url('./images/play-black.svg');
}

.react-video-controls .pause {
	background-image: url('./images/pause-black.svg');
	background-size: 15px;
}

.react-video-controls .volume {
	background-image: url('./images/volume-black.svg');
	background-position-x: 0;
	width: 17px;
}

.react-video-controls .no-volume {
	background-image: url('./images/no-volume-black.svg');
	background-position-x: 0;
	width: 17px;
}

.react-video-controls .full-screen {
	background-image: url('./images/full-screen-black.svg');
	width: 17px;
}

.react-video-controls .speed {
	background-image: url('./images/speed-black.svg');
	background-size: contain;
	width: 17px;
}

.react-video-controls .time {
	color: #969696;
	font-size: 10px;
	line-height: 37px;
	margin: 0 7px;
}

.react-video-controls progress {
	appearance: none;
	-webkit-appearance: none;
	cursor: pointer;
}

.react-video-controls .progress-wrap {
	flex-grow: 1;
	margin: 5px 7px 0;
	position: relative;
}

.react-video-controls .progress-wrap progress {
	height: 8px;
	width: 100%;
}

.react-video-controls .progress-wrap progress::-webkit-progress-value {
	background: #28C0F0;
}

.react-video-controls .progress-wrap progress::-webkit-progress-bar {
	background: #ffffff;
}

.react-video-controls .progress-wrap .react-video-marker {
	background-color: #666666;
	cursor: pointer;
	display: block;
	height: 8px;
	width: 4px;
	position: absolute;
	top: 9px;
	left: 50%;
}

.react-video-controls .volume-wrap {
	position: relative;
}

.react-video-controls .volume-wrap:hover progress {
	display: block;
}

.react-video-controls .volume-wrap progress {
	border-radius: 2px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	display: none;
	height: 12px;
	overflow: hidden;
	position: absolute;
	top: -52px;
	left: -31px;
	transform: rotate(-90deg);
	width: 100px;
}

.react-video-controls .volume-wrap progress::-webkit-progress-value {
	background: #ffffff;
}

.react-video-controls .volume-wrap progress::-webkit-progress-bar {
	background: #9d9d9d;
}

.react-video-full-screen .react-video-wrap {
	background-color: #000000;
	height: 100%;
	width: 100%;
	/* position: fixed; */
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
}

.react-video-full-screen .react-video-player {
	height: calc(100vh - 36px);
	max-width: 100%;
}

.react-video-full-screen .react-video-close {
	background: url('./images/close-video.svg') no-repeat transparent;
	border: 0;
	cursor: pointer;
	opacity: 1;
	outline: none;
	text-indent: -9999px;
	height: 23px;
	width: 23px;
	position: absolute;
	top: 36px;
	right: 77px;
}

.react-video-full-screen .react-video-controls {
	background-color: #595959;
}

.react-video-full-screen .react-video-controls .play {
	background-image: url('./images/play-white.svg');
}

.react-video-full-screen .react-video-controls .pause {
	background-image: url('./images/pause-white.svg');
}

.react-video-full-screen .react-video-controls .volume {
	background-image: url('./images/volume-white.svg');
}

.react-video-full-screen .react-video-controls .no-volume {
	background-image: url('./images/no-volume-white.svg');
}

.react-video-full-screen .react-video-controls .full-screen {
	background-image: url('./images/full-screen-white.svg');
}

.react-video-full-screen .react-video-controls .speed {
	background-image: url('./images/speed-white.svg');
}

.react-video-full-screen .react-video-controls .time {
	color: #ffffff;
}

.speedDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	width: 80px;
	height: 180px;
	z-index: 1;
	background-color: rgba(29, 29, 29, 0.719);
	border-radius: 10px;
	color: rgb(255, 255, 255);
	position: absolute;
	right: 10px;
	bottom: 40px;
	font-size: smaller;
	display: none;
	padding: 10px 0;
}

.showBox {
	display: flex;
}

.insideSpeedDiv {
	height: 40px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color 0.5s ease;
	cursor: pointer;
	border-radius: 10px;
	padding: 2px 0;
	letter-spacing: 1px;
}

.insideSpeedDiv:hover {
	background-color: black;
}

.video-js .vjs-control-bar {
	position: relative;
	top: 570px;
	background-color: transparent;
	color: #000000;
	height: 50px;
	display: flex;
	align-items: center;
	/* width: 50%; */
}

@media screen and (min-width: 1011px) and (max-width: 1250px){
    .video-js .vjs-control-bar {
        width:100% !important;
    }
}
@media screen and (min-width: 750px) and (max-width: 1010px){
    .video-js .vjs-control-bar {
        width:100% !important;
    }
}
@media screen and (min-width: 610px) and (max-width: 750px){
    .video-js .vjs-control-bar {
        width:100% !important;
    }
}
@media screen and (max-width: 610px){
    .video-js .vjs-control-bar {
        width:100% !important;
    }
}

 




@media screen and (min-width: 1011px) and (max-width: 1250px){
    .CustomVideoStyle .vjs-big-play-centered {
        width:100% !important;
    }
}
@media screen and (min-width: 750px) and (max-width: 1010px){
    .CustomVideoStyle .vjs-big-play-centered {
        width:100% !important;
    }
}
@media screen and (min-width: 610px) and (max-width: 750px){
    .CustomVideoStyle .vjs-big-play-centered {
        width:100% !important;
    }
}
@media screen and (max-width: 610px){
    .CustomVideoStyle .vjs-big-play-centered {
        width:100% !important;
    }
}


.vjs-button>.vjs-icon-placeholder:before {
	font-size: 25px;
    line-height: 1.5;
}

.video-js .vjs-progress-control {
	cursor: pointer;
	flex: auto;
	display: flex;
	align-items: center;
	min-width: 4em;
	touch-action: none;
	height: 100%;
}

.video-js .vjs-control {
	display: flex;
	align-items: center;
}

.video-js .vjs-progress-control .vjs-progress-holder {
	margin: 0;
	height: 15%;
}

.video-js .vjs-play-progress:before {
	font-size: 1.5em;
	position: absolute;
	right: -0.5em;
	line-height: 0.35em;
	z-index: 1;
}

.video-js .vjs-volume-panel {
	display: flex;
	align-items: center;
}

.video-js .vjs-volume-level {
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: #0a5c48;
	height: 3px;
}

.vjs-play-progress.vjs-slider-bar {
	background-color: #0a5c48;
}

.vjs-remaining-time.vjs-time-control.vjs-control {
	display: flex;
	align-items: center;
	font-size: 1.2em;
}

.vjs-playback-rate .vjs-playback-rate-value {
	pointer-events: none;
	font-size: 1.5em;
	line-height: 2.5;
	text-align: center;
}

.vjs-playback-rate .vjs-menu {
	width: 8em;
	left: -2em;
	bottom: 10px;
}

.vjs-menu li.vjs-selected,
.vjs-menu li.vjs-selected:focus,
.vjs-menu li.vjs-selected:hover,
.js-focus-visible .vjs-menu li.vjs-selected:hover {
	background-color: rgb(41 60 89 / 50%);
	color: #ffffff;
}

.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
	background-color: rgb(255, 255, 255);
    color: black;
    position: absolute;
    /* width: 100%; */
    bottom: 1.5em;
    max-height: 15em;
    border-radius: 10px;
    border: 1px solid rgb(192, 192, 192);
    height: 150px;
    width: 105px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
	box-shadow: rgba(58, 17, 95, 0.14) 0px 0px 0px 1px, rgba(43, 34, 51, 0.12) 0px 4px 24px;
}

.video-js .vjs-picture-in-picture-control {
	display: none;
}

.vjs-fullscreen .vjs-control-bar {
	background-color: white;
	top: 90%;
}

.css-pw84ay-MuiLinearProgress-root {
	position: relative;
	overflow: hidden;
	display: block;
	height: 4px;
	z-index: 0;
	background-color: rgb(167, 202, 237);
	height: 20px;
	width: 100%;
	top: 25px;
}

.custom-progress-bar {
	position: absolute;
	width: 100%;
	height: 20px;
	/* padding: 0 10px; */
	top: -25px;
	display: flex;
	align-items: center;
}

.custom-progress-bar progress {
	width: 100%;
	height: 100%;
}

.video-js .vjs-progress-control:hover .vjs-time-tooltip,
.video-js .vjs-progress-control:hover .vjs-progress-holder:focus .vjs-time-tooltip {
	display: block;
	font-size: 0.6em;
	visibility: visible;
	top: -25px;
	right: -45px !important;
	box-shadow: rgba(58, 17, 95, 0.14) 0px 0px 0px 1px, rgba(43, 34, 51, 0.12) 0px 4px 24px;
}

progress {
	width: 100%;
	height: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	overflow: hidden;
}

progress::-webkit-progress-value {
	background-color: #f1f1f1;
	border-right: 2px solid black;
}

progress::-webkit-progress-bar {
	background-color: #f1f1f1;
	border-radius: 5px;
}

.markers {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	position: absolute;
}

.marker {
	position: absolute;
	height: 8px;
	width: 8px;
	border-radius: 8px;
	justify-content: center;
	display: flex;
}

.tooltip {
	position: absolute;
	top: -4.5em;
	background-color: #ffffff;
	color: black;
	font-size: 12px;
	display: flex;
	align-items: center;
	border-radius: 5px;
	line-height: 15px;
	letter-spacing: 0.5px;
	opacity: 0;
	box-shadow: rgba(58, 17, 95, 0.14) 0px 0px 0px 1px, rgba(43, 34, 51, 0.12) 0px 4px 24px;
	max-width: 200px;
	padding: 5px 15px;
	height: 45px;
	min-width: 100px;
	justify-content: center;
}

.tooltip-inner {
	text-align: left;
	width: max-content;
}

.tooltip-visible {
	opacity: 1;
}

.hide {
	visibility: hidden !important;
}

button.vjs-play-control.vjs-control.vjs-button.custom-play-toggle-class {
    justify-content: center;
    width: 60px;
    height: 80%;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(192, 192, 192);
    box-shadow: rgba(43, 34, 51, 0.04) 0px 1px 2px;
	transition: background-color 0.3s ease;
}

button.vjs-play-control.vjs-control.vjs-button.custom-play-toggle-class:hover{
	background-color: rgb(238, 238, 238);
}

span.custom-icon {
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}

span.custom-icon .play-icon {
	height: 35px;
	width: 35px;
}

span.custom-icon .pause-icon {
	height: 30px;
	width: 30px;
}

.vjs-mute-control.vjs-control.vjs-button.vjs-vol-3,
.vjs-mute-control.vjs-control.vjs-button.vjs-vol-2,
.vjs-mute-control.vjs-control.vjs-button.vjs-vol-1,
.vjs-mute-control.vjs-control.vjs-button.vjs-vol-0 {
	/* width: 60px; */
    height: 80%;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(192, 192, 192);
    box-shadow: rgba(43, 34, 51, 0.04) 0px 1px 2px;
	margin-left: 5px;
	transition: background-color 0.3s ease;
}

.vjs-mute-control.vjs-control.vjs-button.vjs-vol-3:hover,
.vjs-mute-control.vjs-control.vjs-button.vjs-vol-2:hover,
.vjs-mute-control.vjs-control.vjs-button.vjs-vol-1:hover,
.vjs-mute-control.vjs-control.vjs-button.vjs-vol-0:hover {
	background-color: rgb(238, 238, 238);
}

button.vjs-fullscreen-control.vjs-control.vjs-button {
    height: 80%;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(192, 192, 192);
    box-shadow: rgba(43, 34, 51, 0.04) 0px 1px 2px;
    margin-left: 5px;
    transition: background-color 0.3s ease;
}

button.vjs-fullscreen-control.vjs-control.vjs-button:hover {
	background-color: rgb(238, 238, 238);
}

.vjs-playback-rate.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button {
    height: 80%;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(192, 192, 192);
    box-shadow: rgba(43, 34, 51, 0.04) 0px 1px 2px;
    margin-left: 5px;
    transition: background-color 0.3s ease;
}

.vjs-playback-rate.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button:hover {
	background-color: rgb(238, 238, 238);
}

.vjs-menu-content li:first-child {
    border-radius: 10px 10px 0 0;
}

.vjs-menu-content .vjs-menu-item:last-child {
    border-radius: 0 0 10px 10px;
}

.vjs-menu li {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0.2em 0;
    line-height: 1.4em;
    font-size: 1.2em;
    text-align: center;
    text-transform: lowercase;
    height: 100%;
    align-items: center;
}

.video-js {
	background-color: unset;
}